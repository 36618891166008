import React, { useState, useEffect, useRef } from 'react'
import GameLoginForm from './GameLoginForm'
import GameDashboard from './GameDashboard'
import Popup from 'reactjs-popup'
import close_btn_white from 'src/images/prumdrt2020/close_btn_white.svg'
import game_quiz_bg from '../../images/prumdrt2020/game_quiz_bg.jpg'
import logo from '../../images/prumdrt2020/logo_white.png'
import './styles.scss'
import QuizPool from '../QuizPool'

const LOGIN = 1
const DASHBOARD = 2
const GAME = 3

const GameQuiz = ({
  open,
  onClose,
  locale,
  labels,
  backgrounds,
  translatedText
}) => {
  const [content, setContent] = useState(0)
  const [quizId, setQuizId] = useState(0)
  const [quizLocale, setQuizLocale] = useState(null)
  const translationText = useRef(translatedText)
  useEffect(() => {
    const uuid = window.localStorage.getItem('game_registration_uuid')
    const cacheLocale = window.localStorage.getItem('game_registration_locale')
    if (uuid) {
      setContent(DASHBOARD)
      setQuizLocale(cacheLocale)
    } else {
      setContent(LOGIN)
    }
  }, [open])

  const updateTranslatedText = data => {
    const translation = {
      submit: data.submit,
      next: data.next,
      submitting: data.submitting,
      previous: data.back
    }
    translationText.current = { ...translatedText, ...translation }
  }
  const renderContent = () => {
    if (content === LOGIN) {
      return (
        <GameLoginForm
          verifySuccessCallback={() => setContent(DASHBOARD)}
          locale={locale}
          labels={labels}
          updateLocale={loginLocale => {
            setQuizLocale(loginLocale)
          }}
        ></GameLoginForm>
      )
    } else if (content === DASHBOARD) {
      return (
        <GameDashboard
          locale={quizLocale}
          enterGame={id => {
            setQuizId(id)
            setContent(GAME)
          }}
          updateTranslatedText={data => updateTranslatedText(data)}
        ></GameDashboard>
      )
    } else if (content === GAME) {
      return (
        <QuizPool
          locale={quizLocale}
          idQuizPool={quizId}
          translatedText={translationText.current}
          onCompleteQuiz={() => {
            setContent(DASHBOARD)
          }}
          background={backgrounds.quiz_bg}
        />
      )
    }
  }
  return (
    <Popup
      open={open}
      closeOnDocumentClick
      onClose={onClose}
      contentStyle={{
        width: '100%',
        height: '100%',
        border: 'none',
        padding: 0
      }}
      className="game-quiz-popup"
      modal
      lockScroll
      repositionOnResize
      onOpen={() => {
        document.querySelector('body').style.position = 'fixed'
      }}
      onClose={() => {
        document.querySelector('body').style.position = 'relative'
      }}
    >
      <div
        className={`content-wrapper ${
          content === GAME ? 'content-game-wrapper' : ''
          }`}
        style={{ backgroundImage: `url(${game_quiz_bg})` }}
      >
        {/* <img className="game-quiz-popup-bg" src={game_quiz_bg} alt="" /> */}
        <img src={close_btn_white} className="close-btn" onClick={onClose} />

        <div
          className={`game-popup-main-content ${
            content === LOGIN ? 'game-login' : ''
            }`}
        >
          <img src={logo} className="game-popup-logo" onClick={onClose} />
          {renderContent()}
        </div>
      </div>
    </Popup>
  )
}

export default GameQuiz
