import React, { useState, useEffect } from "react"
import parse from "html-react-parser"

export const OptionWordCloud = ({ option, selected = false, onClickOption }) => {
  const [selectStatus, setSelectStatus] = useState(selected)
  useEffect(() => {

  }, [selectStatus])

  const onClick = (option) => {
    const tmpSelectStatus = !selectStatus
    if (onClickOption(option, tmpSelectStatus)) {
      setSelectStatus(tmpSelectStatus)
    }

  }
  return <div className={`option ${selectStatus ? "selected" : ""}`} onClick={() => onClick(option)}>
    <div className='selection'>
      <div className={"selected"}/>
    </div>
    <div className={"content bold-text italic-class"}>
      {parse(option.content)}
    </div>
  </div>
}

export const OptionQuizMaster = ({ option, selected = false, onClickOption }) => {
  //const [selectStatus, setSelectStatus] = useState(option.selected)
  const selectStatus = option.selected
  useEffect(() => {

  }, [])

  const onClick = (option) => {
    const tmpSelectStatus = true
    if (onClickOption(option, tmpSelectStatus)) {

    }
  }
  return <div className={`option ${selectStatus ? "selected" : ""}`} onClick={() => onClick(option)}>
    <div className={"content bold-text"}>
      {parse(option.content)}
    </div>
  </div>
}


export default { OptionWordCloud, OptionQuizMaster }