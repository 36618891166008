//weir
import React, { useState, useEffect, useRef, useCallback } from 'react'
import parse from 'html-react-parser'
import './style.scss'
import { WordCloudQuiz, QuizMaster } from '../Quiz'
import '../../images/GameQuiz/game_quiz_bg.jpg'
import _ from 'lodash'
import fetchPonyfill from 'fetch-ponyfill'
import SubmitQuizButton from './SubmitQuizButton'
import useSubmitQuizResult from './useSubmitQuizResult'
import fetch from 'src/utils/fetch'

const QuizPool = ({
  translatedText = {},
  open,
  onCloseModel,
  locale = 'en',
  customClass = '',
  idQuizPool = 11,
  background,
  onCompleteQuiz = () => {}
}) => {
  const [quizPool, setQuizPool] = useState(null)
  const [quizzes, setQuizzes] = useState([])
  const [firstLoad, setFirstLoad] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const api = `game_questions?quiz_id=${idQuizPool}`
  const submitResultApi = `submit_game_result`
  const submittedQuizzes = useRef([])
  const [submit, isSubmitting] = useSubmitQuizResult({
    api: submitResultApi
  })
  const currentQuizIndex = quizzes.findIndex(e => !!e.active)

  useEffect(() => {
    if (!firstLoad) {
      fetch(api, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(res => {
          const getQuizPool = _.find(res, obj => obj.locale === locale)

          if (!!getQuizPool) {
            setQuizPool(getQuizPool.game)
            const getQuizzes = getQuizPool.game.quizzes.map((quiz, index) => ({
              ...quiz,
              active: index === 0
            }))
            setQuizzes([...getQuizzes])
            submittedQuizzes.current = [...getQuizPool.game.quizzes]
          }
        })
        .then(() => {
          setFirstLoad(true)
        })
    }
  }, [])

  // Hide zoho chat float
  useEffect(() => {
    const zohoChat = document.querySelector('.zsiq_floatmain')
    if (!zohoChat) return
    const displayCss = zohoChat.style.display
    zohoChat.style.display = 'none'
    return () => {
      if (!zohoChat) return
      zohoChat.style.display = displayCss
    }
  }, [])

  const renderLoading = () => {
    return (
      <div className="loading">
        {parse(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           style="margin: auto; background: rgba(255, 255, 255,0); display: block; shape-rendering: auto;" width="200px"
           height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ffffff"
                stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round"
                transform="rotate(262.644 50 50)">
          <animateTransform attributeName="transform" type="rotate" dur="1.2987012987012987s" repeatCount="indefinite"
                            keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
        <circle cx="50" cy="50" r="23" stroke-width="8" stroke="#ffffff"
                stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none"
                stroke-linecap="round" transform="rotate(-262.644 50 50)">
          <animateTransform attributeName="transform" type="rotate" dur="1.2987012987012987s" repeatCount="indefinite"
                            keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
        </circle>
      </svg>`)}
      </div>
    )
  }

  const onClickSubmit = () => {
    if (isSubmitting) return true
    if (currentQuizIndex < 0) return
    if (currentQuizIndex < quizzes.length - 1) {
      goToNextQuiz(currentQuizIndex)
    } else {
      submitResult()
    }
  }

  const onClickPrev = () => {
    if (currentQuizIndex > 0) {
      goPrevQuiz(currentQuizIndex)
    }
  }

  const submitResult = () => {
    const formattedSubmittedQuizzes = submittedQuizzes.current.map(quiz => {
      return {
        id: quiz.id,
        options: _.filter(quiz.options, option => option.selected)
      }
    })
    // Submit quiz result here
    const data = {
      game_result: {
        quiz_pool_id: idQuizPool,
        game_answers_attributes: buildGameAnswerAttributes(
          formattedSubmittedQuizzes
        )
      }
    }

    setErrorMessage('')
    submit(
      data,
      () => {
        onCompleteQuiz()
      },
      err => {
        // FIXME: Should be changed
        setErrorMessage(err.error_message || 'Please try again.')
      }
    )
  }

  const buildGameAnswerAttributes = formattedSubmittedQuizzes => {
    return formattedSubmittedQuizzes.map(quiz => ({
      quiz_id: quiz.id,
      game_answer_options_attributes: quiz.options
        .filter(e => !!e.selected)
        .map(e => ({
          option_id: e.id
        }))
    }))
  }

  const goToNextQuiz = currentIndex => {
    let nextIndex = currentIndex + 1
    let newQuizzes = quizzes.map((e, index) => ({
      ...e,
      active: index === nextIndex
    }))
    setQuizzes([...newQuizzes])
  }

  const goPrevQuiz = currentIndex => {
    let nextIndex = currentIndex - 1
    let newQuizzes = quizzes.map((e, index) => ({
      ...e,
      active: index === nextIndex
    }))
    setQuizzes([...newQuizzes])
  }

  const onUpdateQuiz = quiz => {
    submittedQuizzes.current = submittedQuizzes.current.map(curQuiz =>
      quiz.id == curQuiz.id ? quiz : curQuiz
    )
    setQuizzes(prevQuizzes =>
      prevQuizzes.map(curQuiz => (quiz.id == curQuiz.id ? quiz : curQuiz))
    )
  }
  const renderContent = () => {
    const quizCollection = quizzes.map(quiz => {
      if (quiz.quiz_type === 'word_cloud') {
        return (
          <WordCloudQuiz
            key={quiz.id}
            quiz={quiz}
            onUpdateQuiz={quiz => onUpdateQuiz(quiz)}
          />
        )
      }
      return (
        <QuizMaster
          key={quiz.id}
          quiz={quiz}
          onUpdateQuiz={quiz => onUpdateQuiz(quiz)}
        />
      )
    })

    const isLastQuizActive = () => {
      return currentQuizIndex === quizzes.length - 1
    }

    const isFirstQuizActive = () => {
      return currentQuizIndex === 0
    }

    const currentActiveQuiz = () => {
      if (currentQuizIndex < 0) return undefined
      return quizzes[currentQuizIndex]
    }

    const shouldDisableSubmitButton = () => {
      if (isSubmitting) return true

      const currentQuiz = currentActiveQuiz()
      if (!currentQuiz) return true

      const selectedOption = currentQuiz.options.find(e => e.selected)
      return !selectedOption
    }

    const submitButtonText = () => {
      const {
        submitting = 'Submitting...',
        submit = 'Submit',
        next = 'Next'
      } = translatedText
      if (isSubmitting) return submitting
      return isLastQuizActive() ? submit : next
    }

    const prevButtonText = () => {
      const { previous = 'Previous' } = translatedText
      return previous
    }

    return (
      <div className={`quiz-pool`}>
        <div className={`quiz-pool-title`}>
          <div className={`title`}>{parse(quizPool.game)}</div>
          <div className="author">{parse(quizPool.name)}</div>
        </div>
        <div className={`quizzes-wrapper`}>{quizCollection}</div>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        <div
          className={`quizzes-buttons-wrapper ${
            isFirstQuizActive() ? '' : 'has-previous'
          }`}
        >
          {!isFirstQuizActive() ? (
            <div
              className={`btn-wrapper previous-btn-wrapper submit-btn-wrapper`}
            >
              <button
                className={`previous-btn submit-btn`}
                onClick={() => onClickPrev()}
              >
                {prevButtonText()}
              </button>
            </div>
          ) : null}
          <div className={`btn-wrapper submit-btn-wrapper`}>
            <SubmitQuizButton
              text={submitButtonText()}
              disabled={shouldDisableSubmitButton()}
              onClick={onClickSubmit}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`quiz-pool-wrapper ${locale} ${!firstLoad ? 'loading' : ''}`}
      style={{ backgroundImage: `url(${background})` }}
    >
      {!firstLoad ? renderLoading() : renderContent()}
      {isSubmitting && (
        <div className="overlay-loading"> {renderLoading()}</div>
      )}
    </div>
  )
}
export default QuizPool
