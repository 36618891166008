//Weir
import React, { useState, useRef, useEffect } from "react"
import parse from "html-react-parser"
import "./style.scss"
import _ from "lodash"
import { OptionWordCloud, OptionQuizMaster } from "./Option"

export const WordCloudQuiz = ({
                                open,
                                customClass = "",
                                quiz,
                                onUpdateQuiz,
                              }) => {
  const quizType = quiz.quiz_type
  const limitSelection = quiz.correct_answer_no
  const mappedOptions = quiz.options.map(option => ({
    ...option,
    ...{ selected: false },
  }))
  const quizOptions = useRef(mappedOptions)
  const onClickOptionWordCloud = (option, status) => {
    const id = option.id
    const chosenAnswer = _.filter(quizOptions.current, opt => opt.selected).map(
      opt => opt.id,
    )
    if (chosenAnswer.length >= limitSelection && !chosenAnswer.includes(id)) {
      return false
    }
    quizOptions.current = quizOptions.current.map(opt => {
      if (opt.id === id) {
        return { ...opt, ...{ selected: status } }
      } else {
        return { ...opt }
      }
    })
    const updatedQuiz = { ...quiz, ...{ options: quizOptions.current } }
    onUpdateQuiz(updatedQuiz)
    return true
  }

  const renderWordCloudOptions = options => {
    const optionsElements = options.map(option => {
      return (
        <OptionWordCloud
          key={option.id}
          option={option}
          onClickOption={(opt, status) => onClickOptionWordCloud(opt, status)}
        />
      )
    })
    return optionsElements
  }

  const renderWordCloud = () => {
    return (
      <div className={`quiz word_cloud`}>
        <div className={"word-cloud-img-wrapper"}>
          <img src={quiz.image} alt={quiz.quiz_no} className={"word-cloud"}/>
        </div>
        <div className={"question-section"}>
          <div className={`question bold-text italic-class`}>
            {parse(quiz.question)}
          </div>
          <div className={`options-wrapper`}>
            {renderWordCloudOptions(quizOptions.current)}
          </div>
        </div>
      </div>
    )
  }

  return <div className={`quiz-wrapper ${quizType}`}>{renderWordCloud()}</div>
}

export const QuizMaster = ({ quiz, onUpdateQuiz }) => {
  const quizType = quiz.quiz_type
  const mappedOptions = quiz.options.map(option => ({ ...option, ...{ selected: false } }))
  const [renderedOptions, setRenderedOptions] = useState(mappedOptions)
  const quizOptions = useRef(mappedOptions)
  const onClickOptionQuizMaster = (option, status) => {
    const id = option.id

    quizOptions.current = quizOptions.current.map(opt => {
      if (opt.id === id) {
        return { ...opt, ...{ selected: status } }
      } else {
        return { ...opt, ...{ selected: !status } }
      }
    })
    setRenderedOptions(quizOptions.current)
    const updatedQuiz = { ...quiz, ...{ options: quizOptions.current } }

    onUpdateQuiz(updatedQuiz)
    return true
  }

  const renderQuizMasterOptions = options => {
    const optionsElements = options.map(option => {
      return (<OptionQuizMaster key={option.id} option={option} selected={option.selected}
                                onClickOption={(opt, status) => onClickOptionQuizMaster(opt, status)}/>)
    })
    return optionsElements
  }

  useEffect(() => {

  }, [renderedOptions])

  const renderQuizMaster = () => {
    return (
      <div className={`quiz quiz_master `}>
        <div className={`question-section ${quiz.title_group !== null ? "has-title-group" : ""}`}>
          {quiz.title_group !== null ? <div className={`question-title-group`}>
            {parse(`${quiz.title_group} `)}
          </div> : null}
          <div className={`question bold-text`}>
            <span className={`quiz-number`}>{parse(`${quiz.quiz_no} `)}</span>
            <span className={`question-text`}>{parse(quiz.question)}</span>
          </div>
          <div className={`options-wrapper`}>
            {renderQuizMasterOptions(renderedOptions)}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={`quiz-wrapper ${quizType} ${
        !quiz.active ? "hidden" : "active"
      }`}
    >
      {renderQuizMaster()}
    </div>
  )
}

export default { WordCloudQuiz, QuizMaster }
