import { useState } from 'react'
import fetch from 'src/utils/fetch'

export default function useSubmitQuizResult(opts = {}) {
  const [loading, setLoading] = useState(false)

  function buildFormData(data) {
    const formData = new FormData()

    function serializeData(prefix, data) {
      Object.keys(data).forEach(key => {
        if (Array.isArray(data[key])) {
          data[key].forEach(arrData => {
            serializeData(`${prefix}[${key}][]`, arrData)
          })
        } else if (typeof data[key] == 'object') {
          serializeData(`${prefix}[${key}]`, data[key])
        } else {
          formData.append(`${prefix}[${key}]`, data[key])
        }
      })
    }

    serializeData('game_result', data.game_result)

    return formData
  }

  function submit(data, callback = () => {}, errorCallback = () => {}) {
    if (loading) return
    setLoading(true)
    const formData = buildFormData(data)
    const uuid = window.localStorage.getItem('game_registration_uuid')
    fetch(opts.api, {
      method: opts.method || 'POST',
      body: formData,
      headers: {
        Authorization: uuid
      }
    }).then(res => {
      setLoading(false)
      if (res.success) {
        callback(res)
      } else {
        errorCallback(res)
      }
    })
  }

  return [submit, loading]
}
