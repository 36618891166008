import React, { memo } from 'react'

export default memo(function SubmitQuizButton({
  className = '',
  disabled,
  text = 'Submit',
  ...props
}) {
  const disabledClass = disabled ? 'disabled' : ''
  return (
    <button
      className={`submit-btn ${className} ${disabledClass}`}
      disabled={disabled}
      {...props}
    >
      {text}
    </button>
  )
})
