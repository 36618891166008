import React, { useEffect, useState } from 'react'
import './GameDashboard.scss'
import fetch from 'src/utils/fetch'
import parse from 'html-react-parser'

const GameDashboard = ({ locale, enterGame, updateTranslatedText }) => {
  const [data, setData] = useState(null)
  const [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    const uuid = window.localStorage.getItem('game_registration_uuid')

    fetch('gamification_dashboard', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: uuid
      }
    }).then(resultData => {
      // console.log(resultData)
      const localeData = resultData[locale]
      setData(localeData)
      if (updateTranslatedText) {
        updateTranslatedText(localeData)
      }

      if (!firstLoad) {
        setFirstLoad(true)
      }
    })
  }, [])
  const clickToGame = id => {
    if (enterGame) {
      enterGame(id)
    }
  }

  const renderLoading = () => {
    return (
      <div className="loading">
        {parse(`<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
           style="margin: auto; background: rgba(255, 255, 255,0); display: block; shape-rendering: auto;width: 100px;height: 100px" width="200px"
           height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" r="32" stroke-width="8" stroke="#ffffff"
                stroke-dasharray="50.26548245743669 50.26548245743669" fill="none" stroke-linecap="round"
                transform="rotate(262.644 50 50)">
          <animateTransform attributeName="transform" type="rotate" dur="1.2987012987012987s" repeatCount="indefinite"
                            keyTimes="0;1" values="0 50 50;360 50 50"></animateTransform>
        </circle>
        <circle cx="50" cy="50" r="23" stroke-width="8" stroke="#ffffff"
                stroke-dasharray="36.12831551628262 36.12831551628262" stroke-dashoffset="36.12831551628262" fill="none"
                stroke-linecap="round" transform="rotate(-262.644 50 50)">
          <animateTransform attributeName="transform" type="rotate" dur="1.2987012987012987s" repeatCount="indefinite"
                            keyTimes="0;1" values="0 50 50;-360 50 50"></animateTransform>
        </circle>
      </svg>`)}
      </div>
    )
  }
  const renderGameDashboardItem = gameData => {
    let addMargin = true
    for (let gameItem of gameData.games) {
      if (gameItem.display_try_again_btn) {
        addMargin = false
        break
      }
    }
    return (
      <div className={`game-day-item-container`}>
        <div
          className={`game-day-item-content ${
            gameData.available ? '' : 'align-center'
            }`}
        >
          <div className="day-title bold-text">{gameData.day}</div>
          {gameData.available && (
            <div
              className={`game-info-group ${
                gameData.display_enter_btn ? 'enter-btn' : ''
                } ${addMargin ? '' : 'try-again-btn'} ${locale}`}
            >
              {gameData.games.map((gameItem, index) => (
                <div key={index} className="game-item-info">
                  <div className="game-item-info-group">
                    <div className="game-score extra-bold-text">
                      {gameItem.score}
                    </div>
                    <div className="game-name">{gameItem.game}</div>
                  </div>
                  {gameItem.display_try_again_btn && (
                    <div
                      className={`game-button bold-text try-again-btn ${
                        gameItem.disable_try_again_btn ? 'disable' : ''
                        }`}
                      onClick={() => {
                        if (!gameItem.disable_try_again_btn) {
                          clickToGame(gameItem.id)
                        }
                      }}
                    >
                      {data.try_again}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
          {gameData.display_enter_btn && gameData.available && (
            <div
              className="game-button bold-text enter-button "
              onClick={() => {
                clickToGame(gameData.enter_quiz_id)
              }}
            >
              {data.enter}
            </div>
          )}
        </div>
      </div>
    )
  }

  const renderNote = () => (
    <div className={`game-dashboard-note ${locale}`}>{parse(data.note)}</div>
  )

  if (!firstLoad) {
    return renderLoading()
  }

  return data ? (
    <>
      <div className={`game-day-container ${locale}`}>
        {renderGameDashboardItem(data.content[0])}
        {renderGameDashboardItem(data.content[1])}
      </div>
      {renderNote()}
    </>
  ) : null
}

export default GameDashboard
