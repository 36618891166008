import React from "react"
import parse from "html-react-parser"

const ARStickers = ({ stickers, url_template }) => {

  const renderQRCodeLink = (item, text_template) => {
    try {
      return parse(text_template.replace("[:url:]", item.qr_code_url).replace("[:extra_class:]", "no-decoration link bold").replace("[:optional_attr:]", "target='_blank'"))
    } catch (e) {
      return text_template
    }
  }
  const renderComponents = (list, text_template) => {
    if (!!list && list.length > 0) {
      return list.map((item, index) => (
        <div className="sticker-component" key={index}>
          <div className={"component-wrapper"}>
            <div className="profile-photo">
              <img src={item.image} alt="AR Sticker" className="img-profile-photo"/>
            </div>
            <div className="qr-profile">
              <img src={item.qr_code} alt="QR Code AR Sticker" className="img-qr-profile"/>
            </div>
            <div className="qr-code-link">
              {renderQRCodeLink(item, text_template)}
            </div>
          </div>
        </div>
      ))
    }
  }

  return (<div className="grid-stickers-wrapper">
    {renderComponents(stickers, url_template)}
  </div>)
}

export default ARStickers