import React, { useState } from 'react'
import game_login_form_bg from '../../images/prumdrt2020/game_login_form_bg.png'
import './GameLoginForm.scss'
import parse from 'html-react-parser'
import fetch from 'src/utils/fetch'

const GameLoginForm = ({
  labels,
  locale,
  verifySuccessCallback,
  updateLocale
}) => {
  const [registrationId, setRegistrationId] = useState('')
  const [errors, setErrors] = useState({ registration_id: '' })
  const [loading, setLoading] = useState(false)

  const onSubmit = e => {
    // verifySuccessCallback()
    e.preventDefault()
    const formData = new FormData()
    formData.append('registrant[registration_id]', registrationId)
    formData.append('locale', locale)

    if (loading) return true

    fetch('verify_registration_id', {
      method: 'POST',
      body: formData
    }).then(resultData => {
      if (resultData.success) {
        if (resultData.result && resultData.result.uuid) {
          window.localStorage.setItem(
            'game_registration_uuid',
            resultData.result.uuid
          )
          window.localStorage.setItem(
            'game_registration_locale',
            resultData.result.locale
          )
          // console.log('resultData.result.uuid ', resultData.result)
          updateLocale(resultData.result.locale)
          verifySuccessCallback()
        }
      } else {
        setErrors(resultData.errors)
      }
      setLoading(false)
    })
  }

  const renderNote = () => (
    <div className={`game-dashboard-note login-form ${locale}`}>{parse(labels.game_login_note)}</div>
  )

  return (
    <>
      {renderNote()}
      <div className={`game-login-container ${locale}`}>
        <img className="game-login-bg" src={game_login_form_bg} alt="" />
        <div className="game-login-wrapper">
          <div className="form-title extra-bold-text">
            {labels.enter_registration_id}
          </div>
          <div className="fields">
            <form onSubmit={e => onSubmit(e)}>
              <input
                className={'bold-text'}
                type="text"
                value={registrationId}
                onChange={e => setRegistrationId(e.target.value)}
              />
            </form>

            <span className="arrow-right"></span>
            {errors.registration_id && (
              <div className="errors">{errors.registration_id}</div>
            )}
          </div>

          <a
            onClick={e => onSubmit(e)}
            className="game-button game-login-submit-button"
          >
            {labels.submit}
          </a>
        </div>
      </div>

    </>

  )
}

export default GameLoginForm
